import { useEffect, useState } from "react";

export const Projects = () => {
 const [isVisible, setIsVisible] = useState(false);

 useEffect(() => {
  const timeout = setTimeout(() => {
   setIsVisible(true);
  }, 100);

  return () => clearTimeout(timeout);
 }, []);

 return (
  <>
   <div id="projects"></div>
   <div className="border-t-2 my-20 border-t-secondary mx-10">
    <h1 className="text-6xl font-bold mt-10 mb-10">Projects</h1>
    <div className="flex justify-center">
     <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
      <a
       href="https://github.com/CalebSullivan35/CareConnect"
       className={`custom-fade-in ${isVisible ? "opacity-100" : "opacity-0"}`}
      >
       <div className=" w-64 sm:w-fit card bg-primary bg-opacity-60 flex justify-center flex-col hover:bg-opacity-70">
        <h1 className="text-2xl sm:text-5xl mb-4 font-semibold">CareConnect</h1>
        <div className="w-42 sm:w-96 sm:h-96 mx-auto rounded-2xl overflow-hidden">
         <img
          src="/CareConnect.png"
          alt="Care Img"
          className="object-cover w-full h-full"
         ></img>
        </div>
        <p className="px-4 text-left sm:text-xl mt-5 sm:w-96">
         CareConnect is a React front-end capstone project that functions as an
         appointment manager and messaging system for patients and their
         assigned healthcare providers.
        </p>
       </div>
      </a>
      <a
       href="https://github.com/CalebSullivan35/MovieMatch"
       className={`custom-fade-in ${isVisible ? "opacity-100" : "opacity-0"}`}
      >
       <div className=" w-64 sm:w-fit card bg-primary bg-opacity-60 flex justify-center flex-col hover:bg-opacity-70">
        <h1 className="text-2xl sm:text-5xl mb-4 font-semibold">MovieMatch</h1>
        <div className="w-42 sm:w-96 sm:h-96 mx-auto rounded-2xl overflow-hidden">
         <img
          src="/MovieMatch.png"
          alt="Care Img"
          className="object-cover w-full h-full"
         ></img>
        </div>
        <p className="px-4 text-left sm:text-xl mt-5 sm:w-96">
         MovieMatch is a Full Stack Web application designed to cater to movie
         enthusiasts. It serves as a platform that empowers users to search for
         and review movies.
        </p>
       </div>
      </a>
      <a
       href="https://github.com/CalebSullivan35/personal-website"
       className={`custom-fade-in ${isVisible ? "opacity-100" : "opacity-0"}`}
      >
       <div className=" w-64 sm:w-fit card bg-primary bg-opacity-60 flex justify-center flex-col hover:bg-opacity-70">
        <h1 className="text-2xl sm:text-5xl mb-4 font-semibold">
         Personal Website
        </h1>
        <div className="w-42 sm:w-96 sm:h-96 mx-auto rounded-2xl overflow-hidden">
         <img
          src="/PortfolioWebsite.png"
          alt="Care Img"
          className="object-cover w-full h-full"
         ></img>
        </div>
        <p className="px-4 text-left sm:text-xl mt-5 sm:w-96">
         My personal website is crafted to be easy on the eyes while centering
         around presenting a blend of my professional work and personal
         narrative.
        </p>
       </div>
      </a>
     </div>
    </div>
   </div>
  </>
 );
};
