//profile will hold the headshot and a desciption of me.
import HeadShot from "../HeadShot.png";
import Typewriter from "./TypeWriter";

export const Profile = () => {
 return (
  <div className="flex flex-col items-center  bg-background text-text pt-10">
   <img
    src={HeadShot}
    alt="Caleb Sullivan Headshot"
    className="w-6/12 lg:w-1/3 2xl:w-96 rounded-full"
   ></img>
   <div className="mx-10 md:w-2/3">
    <div>
     <h1 className="text-4xl mt-10">
      <Typewriter text={`Hello There, I'm Caleb`} delay={100} />
     </h1>
     <h3 className="text-2xl">
      Full Stack Software Developer based in Nashville, Tennessee
     </h3>
    </div>
    <div className="flex justify-center flex-col text-xl mx-2 xl:mx-24 text-left">
     <h1 className="text-3xl mt-10 font-semibold">About Me</h1>
     <p className="mt-5">
      I am a full-stack software developer with an interest in problem-solving.
      As a recent graduate of Nashville Software School, an intensive full-time
      software development boot camp, I have acquired skills in HTML, CSS,
      JavaScript, React, C#/.NET, SQL, and gained experience working with Git,
      GitHub, and VS Code
     </p>
     <p className="mt-5">
      Before my dive into Software development, I was invested in the healthcare
      world. As a registered nurse I gained a host of expereience in therapeutic
      communication, teamwork, patient or client satisfaction and identifying
      the needs of others. I have a history of providing excellent services to
      clients, and being a strong member on any team. I'm excited to seek new
      opportunites to merge my healthcare background with my passion for
      technology.
     </p>
     <h1 className=" text-2xl font-bold text-left hover:cursor-pointer mt-3 text-accent hover:text-white">
      <a href="https://drive.google.com/file/d/1nrm9O6BpFU2n1JNeRI13QAuRluLRjAGU/view?usp=sharing">
       My Resume
      </a>
     </h1>
    </div>
   </div>
  </div>
 );
};
