import React, { useState, useEffect } from "react";

const Typewriter = ({ text, delay }) => {
 const [currentText, setCurrentText] = useState("");
 const [currentIndex, setCurrentIndex] = useState(0);

 useEffect(() => {
  if (currentIndex < text.length) {
   const timeout = setTimeout(() => {
    setCurrentText((prevText) => prevText + text[currentIndex]);
    setCurrentIndex((prevIndex) => prevIndex + 1);
   }, delay);

   return () => clearTimeout(timeout);
  }
 }, [currentIndex, delay, text]);

 const arrOfCurrentText = currentText.split(" ");

 return (
  <span className="">
   {arrOfCurrentText.map((word, index) => (
    <span
     key={index}
     className={index === arrOfCurrentText.length - 1 ? "text-accent" : ""}
    >
     {word}{" "}
    </span>
   ))}
  </span>
 );
};

export default Typewriter;
