import "./App.css";
import { Navbar } from "./components/navbar";
import { Profile } from "./components/profile";
import { Projects } from "./components/projects";
import { Footer } from "./components/Footer";

function App() {
 return (
  <div className="App font-SpaceGrotesk bg-background text-text">
   <Navbar />
   <Profile />
   <Projects />
   <Footer />
  </div>
 );
}

export default App;
