import { FaGithub } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";

export const Footer = () => {
 return (
  <>
   <div id="contact"></div>
   <div className="bg-secondary p-5">
    <div className="flex flex-col  items-center">
     <h1 className="text-xl ">Contact Information</h1>
     <p>Email: CalebSullivan357@gmail.com</p>
     <p>Phone: 318-267-1195</p>
     <div className="text-4xl flex mt-2">
      <a href="https://github.com/CalebSullivan35">
       <FaGithub className="mr-5 hover:cursor-pointer hover:text-primary" />
      </a>
      <a href="https://www.linkedin.com/in/calebjsullivan/">
       <IoLogoLinkedin className=" hover:cursor-pointer hover:text-primary" />
      </a>
     </div>
    </div>
   </div>
  </>
 );
};
