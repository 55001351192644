export const Navbar = () => {
 //function to scroll to the top of the page.

 const scrollToTop = (e) => {
  window.scrollTo({
   top: 0,
   behavior: "smooth",
  });
 };

 return (
  <div className="flex justify-between max-[2258px]:bg-background sm:bg-opacity-100 sticky top-0 z-50">
   <div className="p-5 text-xs sm:text-2xl text-text font-bold">
    Caleb Sullivan
   </div>
   <ol className="flex p-5 sm:text-2xl text-primary">
    <li
     className="animateButton mr-5"
     onClick={() => {
      scrollToTop();
     }}
    >
     About
    </li>
    <li className="mr-5 animateButton">
     <a href="#projects">Projects</a>
    </li>
    <li className="mr-5 animateButton">
     <a href="#contact">Contact</a>
    </li>
   </ol>
  </div>
 );
};
